import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Layout from '@components/layout/Layout'
import LearnMoreComponent from '@components/learn-more/learn-more.component'
import SiteLink from '@components/SiteLink'
import ReferenceComponent from '@components/references/reference.component'
import imgAppScreen from '@assets/images/narcolepsy-screening-tools-app.png'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'

import './epworth-sleepiness-scale.scss'

const EpworthSleepinessScale = ({ location }) => {

  const learnMoreContent = {
    className: 'learn-more-wrapper-gray',
    title: 'AASM provides quality measures for managing patients with narcolepsy',
    supText: '2',
    link: '/disease-management/treatment-decisions/',
    linkLabel: 'Discover these approaches',
    tracking: 'ESS SubPage,	click, screener-ess-cta-management considerations',
  }

  const references = [
    {
      content: 'Johns MW. A new method for measuring daytime sleepiness: the Epworth sleepiness scale. ',
      source: 'Sleep. ',
      info: '1991;14(6):540-545.'
    },
    {
      content: 'Krahn LE, Hershner S, Loeding LD, et al. Quality measures for the care of patients with narcolepsy. ',
      source: 'J Clin Sleep Med. ',
      info: '2015;11(3):335-355.'
    },
    {
      content:
        'Johns M, Hocking B. Excessive daytime sleepiness: daytime sleepiness and sleep habits of Australian Workers. ',
      source: 'Sleep. ',
      info: '1997;20(10):844-849.'
    },
    {
      content: 'American Academy of Sleep Medicine. Central disorders of hypersomnolence. In: ',
      source: 'The International Classification of Sleep Disorders – Third Edition (ICSD-3) Online Version. ',
      info: 'Darien, IL: American Academy of Sleep Medicine; 2014.'
    }
  ]

  return (
    <Layout location={location} addedClass="epworth-sleepiness-scale">
      <div className="primary-container">
        <h1 className="main-page-header"><SiteLink to="/screening-and-diagnosis/screeners/"><FontAwesomeIcon icon={faAngleLeft} /></SiteLink>Epworth Sleepiness Scale</h1>
        <div className="content-container">
          <p className="page-comment">
            The Epworth Sleepiness Scale (ESS) measures the general level of daytime sleepiness. It is a subjective
            scale that asks the respondent to rate his or her propensity to doze or fall asleep during 8 common daily
            activities to determine the level of daytime sleepiness.<sup>1</sup>
          </p>
          <div className="learn-more-content">
            <LearnMoreComponent learnMoreContent={learnMoreContent} />
            <p className="aasm-footnote ess-sns-footnote">
              AASM, American Academy of Sleep Medicine.
            </p>
          </div>
        </div>
      </div>
      <div className="feature-wrapper">
        <div className="primary-container">
          <div className="epworth-features-header">
            <h2 className="section-header">Features of the ESS</h2>
            <SiteLink to="/resources/resource-center/" tracking="ESS SubPage, click, ess subpage download tools - clinical resources">
              Download screening tools
            </SiteLink>
          </div>
          <div className="primary-card">
            <div>
              <div className="feature-item">
                <div className="feature-name">Purpose:</div>
                <p className="feature-content">
                  Designed to measure general level of daytime sleepiness<sup>1</sup>
                </p>
              </div>
              <div className="feature-item">
                <div className="feature-name">Population:</div>
                <p className="feature-content">
                  All patients for whom the clinician may want to evaluate the level of daytime sleepiness<sup>1</sup>
                </p>
              </div>
              <div className="feature-item">
                <div className="feature-name">Assessments:</div>
                <p className="feature-content">
                  Assesses the responder&apos;s propensity to doze or fall asleep during 8 common daily activities, such as:
                  sitting and reading; sitting inactive in a public place; sitting and talking to someone; sitting
                  quietly after a lunch without alcohol; or in a car, while stopped for a few minutes in traffic<sup>
                    1,3
                  </sup>
                </p>
              </div>
              <div className="feature-item">
                <div className="feature-name">Method:</div>
                <p className="feature-content">
                  Patient self-report<sup>1</sup>
                </p>
              </div>
              <div className="feature-item">
                <div className="feature-name">Time required:</div>
                <p className="feature-content">
                  Consists of 8 questions and takes only a few minutes to complete<sup>1</sup>
                </p>
              </div>
              <div className="feature-item">
                <div className="feature-name">Scoring:</div>
                <p className="feature-content">
                  Propensity for dozing is rated for each situation on a 4-point scale, from 0, indicating “would never
                  doze,” to 3, indicating a “high chance of dozing.” Adding the scores for each of the 8 questions
                  yields a total score ranging from 0-24.<sup>1,3</sup>
                </p>
              </div>
              <div className="feature-item">
                <div className="feature-name">Interpretation:</div>
                <p className="feature-content">
                  An ESS score &gt;10 suggests excessive daytime sleepiness (EDS).<sup>2</sup> An ESS score ≥16 suggests a
                  high level of EDS.<sup>1</sup> Scores within this range are generally associated with significant
                  sleep disorders, including narcolepsy.<sup>1</sup> A high ESS score is suggestive of EDS only and is
                  not diagnostic for a specific sleep disorder. Patients with EDS (ie, ESS &gt;10) may need to be evaluated
                  for a potential sleep disorder, including narcolepsy.<sup>1,3</sup>
                </p>
              </div>
            </div>
            <p className="feature-comment">
              A narcolepsy diagnosis should be established by a sleep specialist using a clinical interview and a
              nighttime polysomnogram (PSG) followed by a multiple sleep latency test (MSLT) performed under
              standardized conditions.<sup style={{fontStyle: 'normal'}}>4</sup>
            </p>
          </div>
        </div>
      </div>
      <div className="primary-container">
        <div className="app-comment">
          The Epworth Sleepiness Scale is from: Johns MW. A new method for measuring daytime sleepiness: the Epworth
          sleepiness scale. <i>Sleep</i>. 1991;14(6):540-545. This copyrighted material is used with permission granted
          by the Associated Professional Sleep Societies - October 2012. Unauthorized copying, printing, or distribution
          of this material is strictly prohibited.
        </div>
      </div>
      <ReferenceComponent referenceList={references} />
    </Layout>
  )
}

export default EpworthSleepinessScale
